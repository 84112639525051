import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { Navbar, Nav, Collapse, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";

/**
 * This component will be shared across all pages.
 */

const useStyles = makeStyles(() => ({
  active: {
    fontFamily: "OpenSans-Medium !important",
    color: "#4e41a3 !important",
  },
  navbar: {
   height: "3em",
   "@media (max-width:767px)": {
      height: "2em",
    },
  },
}));

export default function ScheduleNavbar() {
  const theme = createTheme();
  const classes = useStyles();

  theme.typography.h3 = {
      fontSize: '2rem',
      fontFamily: "OpenSans",
      color: "#009DA0",
      [theme.breakpoints.down('lg')]: {
        fontSize: '1rem',
      },
    };

  const [weekDay, setWeekday] = useState("");
  return (
    <ThemeProvider theme={theme}>
      <Navbar color="light" light expand="xs">
        <Collapse navbar>
          <Nav
            className={`navbar-collapse collapse justify-content-center ${classes.navbar}`}
            justified
          >
            <NavItem onClick={()=>setWeekday("lunes")}>
              <NavLink to= "/programacion/lunes">
                <Typography variant="h3" className={weekDay === "lunes" ? classes.active : ""}>LUN</Typography>
              </NavLink>
            </NavItem>
            <NavItem onClick={()=>setWeekday("martes")}>
              <NavLink to="/programacion/martes">
                <Typography variant="h3" className={weekDay === "martes" ? classes.active : ""}>MAR</Typography>
              </NavLink>
            </NavItem>
            <NavItem onClick={()=>setWeekday("miercoles")}>
              <NavLink to="/programacion/miercoles">
                <Typography variant="h3" className={weekDay === "miercoles" ? classes.active : ""}>MIER</Typography>
              </NavLink>
            </NavItem>
            <NavItem onClick={()=>setWeekday("jueves")}>
              <NavLink to="/programacion/jueves">
                <Typography variant="h3" className={weekDay === "jueves" ? classes.active : ""}>JUE</Typography>
              </NavLink>
            </NavItem>
            <NavItem onClick={()=>setWeekday("viernes")}>
              <NavLink to="/programacion/viernes">
                <Typography variant="h3" className={weekDay === "viernes" ? classes.active : ""}>VIER</Typography>
              </NavLink>
            </NavItem>
            {/** check how is saturday saving, with or without accent to make this change */}
            <NavItem onClick={()=>setWeekday("sabado")}>
              <NavLink to="/programacion/sabado">
                <Typography variant="h3" className={weekDay === "sabado" ? classes.active : ""}>SÁB</Typography>
              </NavLink>
            </NavItem>
              <NavItem onClick={()=>setWeekday("domingo")}>
              <NavLink to="/programacion/domingo">
                <Typography variant="h3" className={weekDay === "domingo" ? classes.active : ""}>DOM</Typography>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </ThemeProvider>
  );
}
